import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';

export default class BlueBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
        }
    }

    render() {
        return (<div className={"blue-box"+(!this.state.visible ? " mini" : "")}>
            <div
                className="blue-box-toggler"
                onClick={()=>this.setState({visible: !this.state.visible})}
            >
                <FontAwesomeIcon icon={this.state.visible ? icons.faChevronUp : icons.faChevronDown} />
            </div>
            {this.state.visible ?
                (<>
                    <span>Got the Game?</span>
                    <a href="https://www.familiartalesgame.com">Open the app and start playing!</a>
                </>) : null
            }
            <a className="boldy" href="https://www.familiartalesgame.com">FamiliarTalesGame.com</a>
        </div>);
    }
}