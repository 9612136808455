import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import { motion, AnimatePresence } from "framer-motion";
import Headroom from 'headroom.js';

export default class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showAbout: false,
        };
    }

    componentDidMount() {
        setTimeout(()=>{
            let headroom  = new Headroom(
                document.querySelector("#header-container"),
                {
                    offset: 75,
                    tolerance: {
                        up: 4,
                        down: 0
                    },
                    onUnpin: ()=>this.setState({showAbout: false}),
                }
            );
            headroom.init();
        }, 1);
    }

    idGo(id) {
        this.setState({showAbout: false});
        this.props.idGo(id);
    }

    render() {
        return(<>
            <header>
                <a className="company-logo-container" href="https://www.plaidhatgames.com" target="_blank" rel="nofollow noreferrer">
                    <img
                        className="company-logo" src='/images/plaid-hat-games.png' alt="Plaid Hat Games"
                    />
                </a>
                <nav>
                    <ul>
                        <li>
                            <div className="menu-selector" onClick={()=>this.setState({showAbout: !this.state.showAbout})}>
                                <span>About</span>
                                <FontAwesomeIcon
                                    icon={icons.faChevronDown}
                                    className="about-toggler"
                                />
                            </div>
                            <AnimatePresence>
                                { this.state.showAbout
                                    ? (
                                        <motion.ul
                                            className="submenu"
                                            key="about-submenu"
                                            initial={{height: 0}}
                                            animate={{height: "auto"}}
                                            exit={{height: 0}}
                                            transition={{duration: .4, type: "tween"}}
                                        >
                                            <li onClick={()=>this.idGo("introduction")}>Introduction</li>
                                            <li onClick={()=>this.idGo("trailer")}>Trailer</li>
                                            <li onClick={()=>this.idGo("photos")}>Photos</li>
                                            <li onClick={()=>this.idGo("contents")}>Contents</li>
                                            <li onClick={()=>this.idGo("familiars")}>The Familiars</li>
                                            <li onClick={()=>this.idGo("designer")}>The Designer</li>
                                        </motion.ul>
                                    )
                                    : null
                                }
                            </AnimatePresence>
                        </li>
                        <li onClick={()=>this.idGo("preorder")}>Order</li>
                        <li onClick={()=>this.idGo("videos")}>Videos</li>
                    </ul>
                </nav>
                <a href="https://www.plaidhatgames.com/preorder-familiar-tales/" className="order-now">Order Now!</a>
            </header>
            <hr />
        </>)
    }
}