import React from 'react';
import ReactDOM from 'react-dom';
import Header from './components/Header';
import Slideshow from './components/Slideshow';
import BlueBox from './components/BlueBox';
import $ from 'jquery';

function idGo(id) {
    $('html, body').animate({
        scrollTop: ($("#"+id).offset().top+125)
    }, 500);
}

function resizeStuff() {
    $(".video-container").height($(".video-container").width()*.561);
}
setTimeout(resizeStuff, 100);
window.onresize = resizeStuff;

ReactDOM.render(
    <Header
        idGo={(id)=>idGo(id)}
    />,
    document.getElementById('header-container')
);

ReactDOM.render(
    <Slideshow
        mapSlides={[3]}
        id='intro-slideshow'
        container="intro-slideshow"
        slides={[
            (<>
                <div className="cont straight-down">
                    <h3>Protect the Child!</h3>
                    <p className="dot">Four magical creatures must shield a babe from evil.</p>
                    <p className="dot">Grow and improve your characters with each session.</p>
                    <p className="dot">Watch the child grow over time - but who she becomes depends on you!</p>
                    <img className="pic w40" alt="Blaze and cards" src="/images/game-images/Milly.png"/>
                </div>
                <div className="cont arranged">
                    <div className="arrange-across">
                        <div className="arrange-down">
                            <h3>Protect the Child!</h3>
                            <p className="dot">Four magical creatures must shield a babe from evil.</p>
                            <p className="dot">Grow and improve your characters with each session.</p>
                            <p className="dot">Watch the child grow over time - but who she becomes depends on you!</p>
                        </div>
                        <img className="pic w30" alt="Blaze and cards" src="/images/game-images/Milly.png"/>
                    </div>
                </div>
            </>),
            (<>
                <div className="cont straight-down">
                    <h3>You&nbsp;are&nbsp;a Familiar - a Creature&nbsp;of&nbsp;Magic</h3>
                    <p className="dot">Craft new character-specific cards to improve your familiar as you play.</p>
                    <img className="pic w40" alt="Flicker" src="/images/game-images/Flicker.png"/>
                    <p className="dot">Play as one of four intriguing familiars.</p>
                    <p className="dot">Unique gameplay keeps every character in the game, regardless of player
                        count!</p>
                    <img className="pic w50" alt="Blaze and cards" src="/images/game-images/Blaze_with_Cards.png"/>
                </div>
                <div className="cont arranged">
                    <div className="arrange-across">
                        <div className="arrange-down">
                            <h3>You&nbsp;are&nbsp;a Familiar - a Creature&nbsp;of&nbsp;Magic</h3>
                            <p className="dot">Craft new character-specific cards to improve your familiar as you
                                play.</p>
                        </div>
                        <img className="pic w30" alt="Flicker" src="/images/game-images/Flicker.png"/>
                    </div>
                    <div className="arrange-across">
                        <img className="pic w40" alt="Blaxe and cards" src="/images/game-images/Blaze_with_Cards.png"/>
                        <div className="arrange-down">
                            <p className="dot">Play as one of four intriguing familiars.</p>
                            <p className="dot">Unique gameplay keeps every character in the game, regardless of player
                                count!</p>
                        </div>
                    </div>
                </div>
            </>),
            (<>
                <div className="cont straight-down">
                    <h3>Listen to Your Game Come Alive!</h3>
                    <p className="dot">Interact with your world using a fully-narrated, online app.</p>
                    <p className="dot">Hear the story come alive with professional voice actors and a moving score!</p>
                    <p className="dot">Become part of an epic story spanning more than a decade!</p>
                    <p><a target="_blank" href="/images/voice-cast.jpg" rel="nofollow noreferrer">
                        See the full cast list here!
                    </a></p>
                    <img className="pic w50" alt="Blaze and cards" src="/images/game-images/Phone.png"/>
                </div>
                <div className="cont arranged">
                    <div className="arrange-down">
                        <h3>Listen to Your Game Come Alive!</h3>
                        <div className="arrange-across">
                            <div className="arrange-down">
                                <p className="dot">Interact with your world using a fully-narrated, online app.</p>
                                <p className="dot">Hear the story come alive with professional voice actors and a moving
                                    score!</p>
                                <p className="dot">Become part of an epic story spanning more than a decade!</p>
                                <p><a target="_blank" href="/images/voice-cast.jpg" rel="nofollow noreferrer">
                                    See the full cast list here!
                                </a></p>
                            </div>
                            <img className="pic w30" alt="Blaze and cards" src="/images/game-images/Phone.png"/>
                        </div>
                    </div>
                </div>
            </>),
            (<>
                <div className="cont straight-down">
                    <h3>Explore a Rich Fantasy World</h3>
                    <img className="pic" alt="Blaze and cards" src="/images/game-images/Location_Book.png"/>
                    <p className="dot">Traverse beautiful fantasy landscapes.</p>
                    <p className="dot">Countless secrets lay hidden, just begging to be uncovered!</p>
                    <p className="dot">Choose which paths to wander, and discover areas you might not
                        otherwise&nbsp;find.</p>
                </div>
                <div className="cont arranged">
                    <div className="arrange-down">
                        <h3>Explore a Rich Fantasy World</h3>
                        <div className="arrange-across">
                            <img style={{alignSelf: "flex-start",}} className="pic w70" alt="Blaze and cards"
                                 src="/images/game-images/Location_Book.png"/>
                            <div className="arrange-down">
                                <p className="dot">Traverse beautiful fantasy&nbsp;landscapes.</p>
                                <p className="dot">Countless secrets lay hidden, just begging
                                    to&nbsp;be&nbsp;uncovered!</p>
                                <p className="dot">Choose which paths to wander, and discover areas you might not
                                    otherwise&nbsp;find.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </>),
        ]}
    />,
    document.getElementById('intro-slideshow')
);

ReactDOM.render(
    <Slideshow
        mapSlides={[]}
        id='photo-slideshow'
        container="photo-slideshow"
        slides={[
            <img className="slide-photo" src="images/photos/Box copy.jpg" alt="Box" />,
            <img className="slide-photo" src="images/photos/Blaze.jpg" alt="Blaze" />,
            <img className="slide-photo" src="images/photos/Campaign Journal.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Chalk.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Enemy Tracker.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Familiar Tales_Outside Layout.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Flicker.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Gribbert Outside.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Location Book.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Outside CU Box.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Outside Page.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Outside spread.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/The Baby.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/The Cards.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/The Minis.jpg" alt="" />,
            <img className="slide-photo" src="images/photos/Token Holder.jpg" alt="" />,
        ]}
    />
    , document.getElementById('photo-slideshow')
);

ReactDOM.render(<BlueBox />, document.getElementById('blue-box-container'));

ReactDOM.render(
    <Slideshow
        mapSlides={[3]}
        id='familiar-slideshow'
        container="familiars-slideshow"
        slides={[
            (<>
                <div className="cont straight-down">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="fam-banner gribbert">Gribbert</div>
                    <p className="inset">Gribbert was a highwayman once, until his misdeeds caught the attention of a troublesome witch who turned him into a frog. Gribbert's knowledge of the human world is useful to his friends who find humans frightening and strange.</p>
                    <img className="mini-pic gribbert" src="https://media.plaidhatgames.com/apng/apng_gribbert.png" alt="Gribbert miniature" />
                </div>
                <div className="cont arranged">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="arrange-lean-left">
                        <div className="fam-banner gribbert">Gribbert</div>
                        <p className="inset">Gribbert was a highwayman once, until his misdeeds caught the attention of a troublesome witch who turned him into a frog. Gribbert's knowledge of the human world is useful to his friends who find humans frightening and strange.</p>
                    </div>
                    <img className="mini-pic gribbert" src="https://media.plaidhatgames.com/apng/apng_gribbert.png" alt="Gribbert miniature" />
                </div>
            </>),
            (<>
                <div className="cont straight-down">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="fam-banner chalk">Chalk & Tweets</div>
                    <p className="inset">Chalk is a massive being of rock and leaf and vine. It is ever accompanied by Tweets, the small bird that sits upon its shoulder and chirps out the thoughts that pass through Chalk's mind. Chalk is the strongest of the friends but also the most tender-hearted.</p>
                    <img className="mini-pic chalk" src="https://media.plaidhatgames.com/apng/apng_chalk.png" alt="Chalk miniature" />
                </div>
                <div className="cont arranged">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="arrange-lean-left">
                        <div className="fam-banner chalk">Chalk & Tweets</div>
                        <p className="inset">Chalk is a massive being of rock and leaf and vine. It is ever accompanied by Tweets, the small bird that sits upon its shoulder and chirps out the thoughts that pass through Chalk's mind. Chalk is the strongest of the friends but also the most tender-hearted.</p>
                    </div>
                    <img className="mini-pic chalk" src="https://media.plaidhatgames.com/apng/apng_chalk.png" alt="Chalk miniature" />
                </div>
            </>),
            (<>
                <div className="cont straight-down">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="fam-banner blaze">Blaze</div>
                    <p className="inset">Blaze once served a divine spirit who hunted legendary beasts. Now she brings her keen senses and noble ferocity to the aid of her friends. She loathes failure and has been known to grumble, but few are as selfless.</p>
                    <img className="mini-pic blaze" src="https://media.plaidhatgames.com/apng/apng_blaze.png" alt="Blaze miniature" />
                </div>
                <div className="cont arranged">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="arrange-lean-left">
                        <div className="fam-banner blaze">Blaze</div>
                        <p className="inset">Blaze once served a divine spirit who hunted legendary beasts. Now she brings her keen senses and noble ferocity to the aid of her friends. She loathes failure and has been known to grumble, but few are as selfless.</p>
                    </div>
                    <img className="mini-pic blaze" src="https://media.plaidhatgames.com/apng/apng_blaze.png" alt="Blaze miniature" />
                </div>
            </>),
            (<>
                <div className="cont straight-down">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="fam-banner flicker">Flicker</div>
                    <p className="inset">Flicker is an outcast, once part of a fairy collective that enjoyed helping wizards with their research. Flicker is more scared of humans than the others, but the arrival of the small baby might just change her mind.</p>
                    <img className="mini-pic flicker" src="https://media.plaidhatgames.com/apng/apng_flicker.png" alt="Flicker miniature" />
                </div>
                <div className="cont arranged">
                    <h3 className="boxed">Meet the Familiars</h3>
                    <div className="arrange-lean-left">
                        <div className="fam-banner flicker">Flicker</div>
                        <p className="inset">Flicker is an outcast, once part of a fairy collective that enjoyed helping wizards with their research. Flicker is more scared of humans than the others, but the arrival of the small baby might just change her mind.</p>
                    </div>
                    <img className="mini-pic flicker" src="https://media.plaidhatgames.com/apng/apng_flicker.png" alt="Flicker miniature" />
                </div>
            </>),
        ]}
    />,
    document.getElementById('familiar-slideshow')
);




